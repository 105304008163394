import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function Tel() {
  return (
    <div>
      <a href="tel:+33635337224" name='tel'><FontAwesomeIcon icon={faPhone} shake className='tel'/></a>
    </div>
  );
}

export default Tel;
