import taxi from './taxi11.webp';
import branche from './branche.webp';
import './App.css';
import Tel from './Tel';
import EmailButton from './Button';
import Fix from './Fix';
import Mail from './Mail';
import { useState } from 'react';
import { MdHome } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { faTaxi } from '@fortawesome/free-solid-svg-icons';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FaEnvelope} from "react-icons/fa";



function App() {

  const [switcher, setSwitcher] = useState(false);

  return (
    <div> 
      {switcher ? (
        <>
        <header>
        <img src={branche} className="branche" alt="logo" />
        <div className="text-overlay">
          <p className="text">BIENVENUE</p>
        </div>
       </header>
       <div onClick={() => setSwitcher(!switcher)}>
                      <MdHome className='home_icon'/>
                    </div>
        <Mail />
        </>
      ) : (
        <div className="App">
          <header>
            <img src={branche} className="branche" alt="branche-d'arbre" />
            <div className="text-overlay">
              <p className="text">BIENVENUE</p>
            </div>
          </header>
          <main>
            <section className='home-page'>
            <section className="container">
              <div className="text-container">
              <h1>Chauffeur de Taxi Momo à Bordeaux</h1>

                <div className='icon_container'>
                  <div className='icons'>
                    <Tel />
                    <div onClick={() => setSwitcher(!switcher)}>
                      <EmailButton/>
                    </div>
                    <Fix />
                  </div>
                  
                  <div className='mini_text_container'>
                    <p>RESERVATION POUR :</p>
                    <p className='mini_text'>TAXI SAINT-LOUBES-BORDEAUX <br/> RIVE DROITE</p>
                    <p className='micro-text'>Mohammed Ben Hamouda</p>
                  </div>
                </div>
              </div>
              
              <div className="image-container">
                <img src={taxi} className="taxi" alt="taxi" />
                <p className='text-bas'>Transport gare & aéroport ...</p>
              </div>
            </section>
            </section>
            <div className='container3'>
              <h2>Momo Votre Chauffeur de Taxi !</h2>
              <p>Bienvenue sur le site officiel de Momo,
                votre chauffeur de taxi de confiance.
                Vous pouvez toujours compter sur moi
                pour vous fournir un service de transport sûr,
                fiable et convivial dans la région.</p>
            </div>
            <div className='container2'>
              <div>
                <FontAwesomeIcon icon={faShieldHalved} bounce className='icons'/>
                <h2>Sécurité et Fiabilité Avant Tout</h2>
                <p>Votre sécurité est ma priorité absolue. Je suis un chauffeur professionnel avec toutes les licences et assurances nécessaires. Vous pouvez avoir l'esprit tranquille en sachant que je prends toutes les précautions nécessaires pour assurer votre sécurité pendant chaque trajet.</p>
              </div>
              <div>
                <FontAwesomeIcon icon={faTaxi} bounce className='icons'/>
                <h2>Services de Taxi de Qualité</h2>
                <p>Que vous ayez besoin d'un transfert rapide vers l'aéroport, d'une course en ville, de sorties nocturnes ou de tout autre déplacement, je suis là pour vous servir. Ma flotte de véhicules modernes et bien entretenus assure un voyage confortable, que ce soit en solo, en famille ou en groupe.</p>
              </div>
              <div>
              <FontAwesomeIcon icon={faBolt} bounce className='icons'/>
              <h2>Service de Taxi Rapide et Fiable</h2>
              <p>Si vous avez besoin d'un trajet rapide pour vous rendre à une réunion, à l'aéroport, ou pour toute autre raison, Momo est là pour vous. Il se distingue par sa ponctualité et son engagement envers la satisfaction du client. Il vous assure un voyage sans stress, où chaque minute compte.</p>
              </div>
            </div>
            <footer>
              <div>
                <div><FaEnvelope className='mailf'/></div>
                <h2>e-mail :</h2>
                <p>taxi.momo.bordeaux@gmail.com</p>
              </div>
              <div>
                <div><FontAwesomeIcon icon={faPhone} className='tel'/></div>
                <h2>telephone:</h2>
                <p>+33635337224</p>
                <p>+33557049228</p>
              </div>
            </footer>
          </main>
        </div>
      )}
    </div>
  );
}

export default App;
