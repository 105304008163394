import React, { useRef } from 'react';
import './App.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Mail = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ajp86ca', 'template_be0scbj', form.current, 'm0aLF_5B8qQXtmz_q')
      .then(
        (result) => {
          console.log(result.text);
          // Show a success notification
          toast.success('Votre message a été envoyé avec succès.', { position: 'top-right' });
        },
        (error) => {
          console.log(error.text);
          // Show an error notification
          toast.error('Une erreur s\'est produite lors de l\'envoi du message.', { position: 'top-right' });
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className='container_form'>
        <form ref={form} onSubmit={sendEmail} className='form_control'>
          <h2 className='title_contact'>Contactez Nous</h2>
          <input type="text" placeholder='Nom*' name='user-name' required />
          <input type="text" placeholder='Telephone*' name='user-tel' required />
          <input type="email" placeholder='Email*' name='user_email' required />
          <input type="text" placeholder='Adresse de prise en charge*' name='adrs-prise' required />
          <input type="date" placeholder='Date de prise en charge*' name='date-prise' required />
          <input type="time" placeholder='Heure de prise en charge*' name='time-prise' required />
          <input type="text" placeholder='Destination*' name='desti' required />
          <button type='submit' className='btn'>
            Envoyer
          </button>
        </form>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

export default Mail;
